<template>
    <div class="panel d-flex-afs">
        <side-bar fixed v-model="show"/>
        <div class="main d-flex-ast-jst grow-1">
            <transition name="side-up" mode="out-in">
                <router-view class="main-drop panel-box"/>
            </transition>
        </div>
    </div>
</template>

<script>
    import SideBar from "@/components/Panel/sidebar/SideBar";

    export default {
        name: "Panel",
        components: {SideBar},
        data() {
            return {
                show: true
            }
        }
    }
</script>

<style lang="scss">
    .panel {
        .main {
            min-height: calc(100vh - var(--navbar-h));
            padding: clamp(10px, 2.2vw, 30px);

            .panel-box {
                width: 100%;
                border-radius: 17px;
                background-color: var(--gray-box);
            }
        }

        @media (max-width: 768px) {
            .back-drop{
                display: none;
            }
        }
    }
</style>